<template>
  <div class="notFound">
    <div>
      <h1>404</h1>
      <h2>Halaman tidak Ditemukan</h2>
    </div>
    <div>
      <b-button @click="backToHome" size="is-small">Kembali</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  methods: {
    backToHome() {
      this.$router.push("/");
    },
  },
};
</script>
