<template>
  <section>
    <div class="form my-5">
      <div class="logo_container">
        <img
          src="../assets/img/hik_logo.png"
          alt="logo_bprs_hik"
          class="left"
        />
        <img
          src="../assets/img/moto_logo.png"
          alt="moto_bprs_hik"
          class="right"
        />
      </div>
      <span class="title flex justify-center py-5">
        FORMULIR PERMOHONAN INFORMASI DEBITUR (SLIK OJK) INTERNAL BPRS HIK
        PARAHYANGAN
      </span>
      <section v-if="!switcher.isAo">
        <Form @submit="checkAO">
          <!-- <span class="flex mb-5">Data Pemohon</span> -->
          <b-field label="*Masukkan Kode AO">
            <Field
              name="form.aoCode"
              rules="required"
              v-slot="{ field, errors }"
              :model-value="form.aoCode"
            >
              <b-input
                v-bind="field"
                v-model="form.aoCode"
                placeholder="Masukkan Kode AO"
              />
              <span class="has-text-danger">{{ errors[0] }}</span>
            </Field>
          </b-field>
          <div class="flex justify-center mt-4" style="flex-direction: column">
            <b-button
              native-type="submit"
              type="is-success"
              style="width: 100%"
              :loading="animation.isLoading"
            >
              Kirim
            </b-button>
          </div>
        </Form>
      </section>
      <section v-else>
        <Form @submit="addDebitur">
          <span class="flex mb-5 border-bottom">Data Pemohon</span>
          <b-field label="*Nama Lengkap">
            <b-input v-model="form.name" disabled />
          </b-field>
          <b-field label="*Cabang">
            <b-input v-model="form.branch" disabled />
          </b-field>
          <b-field label="*Bagian">
            <Field
              name="form.position"
              rules="required"
              v-slot="{ field, errors }"
              :model-value="form.position"
            >
              <b-input
                v-bind="field"
                v-model="form.position"
                placeholder="Masukkan Bagian"
              />
              <span class="has-text-danger">{{ errors[0] }}</span>
            </Field>
          </b-field>
          <!-- <b-field label="*Cabang">
            <Field
              name="form.branch"
              rules="required"
              v-slot="{ errors }"
              :model-value="form.branch"
            >
              <b-select
                class="column"
                as="select"
                label="Expanded"
                v-model="form.branch"
                expanded
                style="padding: 0"
              >
                <option value="" disabled>Pilih Cabang</option>
                <option
                  v-for="(item, index) in options.branch"
                  :key="index"
                  :value="item.codeCab"
                >
                  {{ item.name }}
                </option>
              </b-select>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </Field>
          </b-field> -->
          <b-field label="*Email">
            <Field
              name="form.email"
              :rules="{ required: 'required', email: 'email' }"
              v-slot="{ field, errors }"
              :model-value="form.email"
            >
              <b-input
                v-bind="field"
                v-model="form.email"
                placeholder="Masukkan Email"
              />
              <span class="has-text-danger">{{ errors[0] }}</span>
            </Field>
          </b-field>
          <span class="flex my-5 border-bottom">Tujuan Permohonan</span>
          <b-field label="*Alasan pengajuan informasi debitur">
            <Field
              name="form.purpose"
              rules="required"
              v-slot="{ errors }"
              :model-value="form.purpose"
            >
              <b-select
                class="column"
                as="select"
                label="Expanded"
                v-model="form.purpose"
                expanded
                style="padding: 0"
                @change="listenToPurpose"
              >
                <option value="" disabled>Pilih Tujuan</option>
                <option
                  v-for="(item, index) in options.purpose"
                  :key="index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </b-select>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </Field>
          </b-field>
          <b-field label="*Jenis Nasabah">
            <Field
              name="switcher.cifType"
              rules="required"
              v-slot="{ errors }"
              :model-value="switcher.cifType"
            >
              <b-select
                class="column"
                as="select"
                label="Expanded"
                v-model="switcher.cifType"
                expanded
                style="padding: 0"
                @change="listenToCifType"
                :disabled="!switcher.cifTypeOn"
              >
                <option
                  v-for="(item, index) in options.cifType"
                  :key="index"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </b-select>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </Field>
          </b-field>

          <section v-if="switcher.uploadedForm">
            <span class="flex my-5 border-bottom">Unggah Formulir</span>
            <b-field label="*Upload Form Pembiayaan">
              <b-field
                class="file is-primary"
                :class="{ 'has-name': !!doc.loanForm.name }"
              >
                <Field
                  name="doc.loanForm.name"
                  rules="required"
                  v-slot="{ errors }"
                  :model-value="doc.loanForm.name"
                >
                  <b-upload
                    @change="handleFileChange($event, 'loanForm')"
                    class="file-label"
                    required
                    native
                    ref="loanForm"
                  >
                    <span v-if="doc.loanForm.name">
                      <b-button type="is-primary is-light" disabled>{{
                        doc.loanForm.name
                      }}</b-button>
                    </span>
                    <span v-else class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Upload (gambar/pdf/rar)</span>
                    </span>
                  </b-upload>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </Field>
              </b-field>
            </b-field>
            <span class="text-sm" v-if="!doc.loanForm.name"
              >(Maksimal file 1 MB)</span
            >
            <span
              class="txt-del-file"
              @click="resetFile('loanForm')"
              v-if="doc.loanForm.name"
            >
              hapus File
            </span>
            <b-field
              label="*Upload Form Akta Pendirian dan Akta Pengurus Terbaru"
              v-if="switcher.cifType === '2'"
              class="mt-4"
            >
              <b-field
                class="file is-primary"
                :class="{ 'has-name': !!doc.deedOfManagement.name }"
              >
                <Field
                  name="doc.deedOfManagement.name"
                  rules="required"
                  v-slot="{ errors }"
                  :model-value="doc.deedOfManagement.name"
                >
                  <b-upload
                    @change="handleFileChange($event, 'deedOfManagement')"
                    class="file-label"
                    required
                    native
                    ref="deedOfManagement"
                  >
                    <span v-if="doc.deedOfManagement.name">
                      <b-button type="is-primary is-light" disabled>{{
                        doc.deedOfManagement.name
                      }}</b-button>
                    </span>
                    <span v-else class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Upload (gambar/pdf/Rar)</span>
                    </span>
                  </b-upload>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </Field>
              </b-field>
            </b-field>
            <span
              class="text-sm"
              v-if="!doc.deedOfManagement.name && switcher.cifType === '2'"
              >(Maksimal file 10 MB)</span
            >
            <span
              class="txt-del-file"
              @click="resetFile('deedOfManagement')"
              v-if="doc.deedOfManagement.name"
            >
              hapus File
            </span>
          </section>
          <section v-if="switcher.cifType === '1'">
            <span class="flex my-5 border-bottom">Data Nasabah Individu</span>
            <b-field label="*Nama Lengkap">
              <Field
                name="form.cifName"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifName"
              >
                <b-input
                  v-bind="field"
                  v-model="form.cifName"
                  placeholder="Masukkan Nama Lengkap"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*NIK">
              <Field
                name="form.cifCode"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifCode"
              >
                <b-input
                  v-bind="field"
                  v-model="form.cifCode"
                  placeholder="Masukkan NIK"
                  maxlength="16"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Jenis Kelamin">
              <Field
                name="form.cifGender"
                rules="required"
                v-slot="{ errors }"
                :model-value="form.cifGender"
              >
                <b-select
                  class="column"
                  as="select"
                  label="Expanded"
                  v-model="form.cifGender"
                  expanded
                  style="padding: 0"
                >
                  <option value="" disabled>Pilih Jenis Kelamin</option>
                  <option
                    v-for="(item, index) in options.gender"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.name }}
                  </option>
                </b-select>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Tempat Lahir">
              <Field
                name="form.cifBirthPlace"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifBirthPlace"
              >
                <b-input
                  v-bind="field"
                  v-model="form.cifBirthPlace"
                  placeholder="Masukkan Tempat Lahir"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Tanggal Lahir">
              <Field
                name="form.cifBirthDate"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifBirthDate"
              >
                <b-input
                  v-bind="field"
                  type="date"
                  v-model="form.cifBirthDate"
                  placeholder="Masukkan Tanggal Lahir"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Nama Ibu Kandung">
              <Field
                name="form.cifMother"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifMother"
              >
                <b-input
                  v-bind="field"
                  v-model="form.cifMother"
                  placeholder="Masukkan Nama Ibu Kandung"
                />

                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Upload FC KTP" class="mt-4">
              <b-field
                class="file is-primary"
                :class="{ 'has-name': !!doc.cifIdentity.name }"
              >
                <Field
                  name="doc.cifIdentity.name"
                  rules="required"
                  v-slot="{ errors }"
                  :model-value="doc.cifIdentity.name"
                >
                  <b-upload
                    @change="handleFileChange($event, 'ktp')"
                    class="file-label"
                    required
                    native
                    ref="cifIdentity"
                  >
                    <span v-if="doc.cifIdentity.name">
                      <b-button type="is-primary is-light" disabled>{{
                        doc.cifIdentity.name
                      }}</b-button>
                    </span>
                    <span v-else class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Upload (gambar/pdf/Rar)</span>
                    </span>
                  </b-upload>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </Field>
              </b-field>
            </b-field>
            <span class="text-sm" v-if="!doc.cifIdentity.name"
              >(Maksimal file 1 MB)</span
            >
            <span
              class="txt-del-file"
              @click="resetFile('ktp')"
              v-if="doc.cifIdentity.name"
            >
              hapus File
            </span>
          </section>
          <section v-if="switcher.cifType === '2'">
            <span class="flex my-5 border-bottom">Data Nasabah Badan</span>
            <b-field label="*Nama Badan">
              <Field
                name="form.cifName"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifName"
              >
                <b-input
                  v-bind="field"
                  v-model="form.cifName"
                  placeholder="Masukkan Nama Badan"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*NPWP">
              <Field
                name="form.cifCode"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifCode"
              >
                <b-input
                  v-bind="field"
                  v-model="form.cifCode"
                  placeholder="Masukkan NPWP"
                  maxlength="20"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Tanggal Berdiri">
              <Field
                name="form.cifBirthDate"
                rules="required"
                v-slot="{ field, errors }"
                :model-value="form.cifBirthDate"
              >
                <b-input
                  v-bind="field"
                  type="date"
                  v-model="form.cifBirthDate"
                  placeholder="Masukkan Tanggal Berdiri"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </Field>
            </b-field>
            <b-field label="*Upload FC NPWP" class="mt-4">
              <b-field
                class="file is-primary"
                :class="{ 'has-name': !!doc.cifIdentity.name }"
              >
                <Field
                  name="doc.cifIdentity.name"
                  rules="required"
                  v-slot="{ errors }"
                  :model-value="doc.cifIdentity.name"
                >
                  <b-upload
                    @change="handleFileChange($event, 'ktp')"
                    class="file-label"
                    required
                    native
                    ref="cifIdentity"
                  >
                    <span v-if="doc.cifIdentity.name">
                      <b-button type="is-primary is-light" disabled>{{
                        doc.cifIdentity.name
                      }}</b-button>
                    </span>
                    <span v-else class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Upload (gambar/pdf/Rar)</span>
                    </span>
                  </b-upload>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </Field>
              </b-field>
            </b-field>
            <span class="text-sm" v-if="!doc.cifIdentity.name"
              >(Maksimal file 1 MB)</span
            >
            <span
              class="txt-del-file"
              @click="resetFile('ktp')"
              v-if="doc.cifIdentity.name"
            >
              hapus File
            </span>
          </section>
          <div class="flex justify-center mt-6 gap-2">
            <b-button type="is-light" style="width: 100%" @click="routeToBack">
              Kembali
            </b-button>
            <b-button
              native-type="submit"
              type="is-success"
              style="width: 100%"
              :loading="animation.isLoading"
            >
              Simpan
            </b-button>
          </div>
        </Form>
      </section>
    </div>
  </section>
</template>

<script>
import { Form, Field } from "vee-validate";

export default {
  name: "FormSlikOjk",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      form: {
        aoCode: "",
        aoId: "",
        name: "",
        branch: "",
        position: "",
        email: "",
        purpose: "",
        cifName: "",
        cifCode: "",
        cifGender: "",
        cifBirthPlace: "",
        cifBirthDate: "",
        cifMother: "",
        cifCodePic: "",
      },
      doc: {
        cifIdentity: {
          name: "",
          file: "",
          ex: "",
        },
        loanForm: {
          name: "",
          file: "",
          ex: "",
        },
        deedOfManagement: {
          name: "",
          file: "",
          ex: "",
        },
      },
      options: {
        // branch: [
        //   { name: "Pusat", codeCab: "99" },
        //   { name: "KCU", codeCab: "01" },
        //   { name: "Cianjur", codeCab: "02" },
        //   { name: "Soreang", codeCab: "03" },
        //   { name: "Majalengka", codeCab: "04" },
        //   { name: "Kota Bandung", codeCab: "05" },
        //   { name: "Garut / Pameungpeuk", codeCab: "06" },
        //   { name: "Sukabumi", codeCab: "07" },
        //   { name: "Subang", codeCab: "08" },
        //   { name: "Tasikmalaya", codeCab: "09" },
        //   { name: "KBB / Cimahi", codeCab: "10" },
        //   { name: "Sumedang", codeCab: "11" },
        //   { name: "Purwakarta", codeCab: "12" },
        //   { name: "Kuningan", codeCab: "13" },
        //   { name: "Ciamis", codeCab: "14" },
        //   { name: "Pangandaran", codeCab: "15" },
        //   { name: "Karawang", codeCab: "16" },
        //   { name: "Cirebon", codeCab: "17" },
        //   { name: "Indramayu", codeCab: "18" },
        //   { name: "Depok / Bekasi / Caringin", codeCab: "19" },
        // ],
        purpose: [
          { code: "01", name: "Penilaian Calon Debitur" },
          { code: "02", name: "Penilaian One Obligor Concept" },
          { code: "03", name: "Monitoring Debitur Existing" },
          { code: "04", name: "Melayani Permintaan Debitur" },
          { code: "05", name: "Dalam Rangka Pelaksanaan Audit" },
          { code: "06", name: "Penanganan Pengaduan Debitur" },
          { code: "07", name: "Penilaian Karyawan atau Calon Karyawan" },
          {
            code: "08",
            name: "Penilaian Calon Rekanan, Agen, Merchant, mapupun Vendor Pelapor (diperlukan surat permohonan rekanan)",
          },
        ],
        gender: [
          { code: "L", name: "Laki-laki" },
          { code: "P", name: "Perempuan" },
        ],
        cifType: [
          { code: "1", name: "Individu" },
          { code: "2", name: "Badan" },
        ],
      },
      switcher: {
        uploadedForm: true,
        cifType: "1",
        cifTypeOn: true,
        isAo: false,
      },
      animation: {
        isLoading: false,
      },
    };
  },
  methods: {
    async handleFileChange(event, doc) {
      let isFile = false;
      const file = event.target.files[0];
      const extension = event.target.files[0]?.type;
      const name = event.target.files[0]?.name;
      const size = event.target.files[0]?.size;

      if (file) {
        if (extension === "image/jpeg" || extension === "image/png") {
          if (size <= 1048576) {
            isFile = true;
          } else {
            this.$store.dispatch("showError", {
              text: "Melebihi batas maksimal Gambar 1 MB",
            });
          }
        } else if (extension === "application/pdf") {
          if (size <= 1048576) {
            isFile = true;
          } else {
            this.$store.dispatch("showError", {
              text: "Melebihi batas maksimal Pdf 1 MB",
            });
          }
        } else if (extension === "application/x-compressed") {
          if (size <= 10485760) {
            isFile = true;
          } else {
            this.$store.dispatch("showError", {
              text: "Melebihi batas maksimal Rar 10 MB",
            });
          }
        } else {
          this.$store.dispatch("showError", {
            text: "Format file berupa gambar atau pdf atau rar",
          });
        }

        if (isFile) {
          switch (doc) {
            case "ktp":
              this.$refs.cifIdentity.$el.querySelector("input").value = "";
              try {
                this.doc.cifIdentity.name = name;
                this.doc.cifIdentity.file = await this.convertFileToBase64(
                  file
                );
                this.doc.cifIdentity.ex = extension;
              } catch (error) {
                console.error("Error converting file to Base64:", error);
              }
              break;
            case "loanForm":
              this.$refs.loanForm.$el.querySelector("input").value = "";
              try {
                this.doc.loanForm.name = name;
                this.doc.loanForm.file = await this.convertFileToBase64(file);
                this.doc.loanForm.ex = extension;
              } catch (error) {
                console.error("Error converting file to Base64:", error);
              }
              break;
            case "deedOfManagement":
              this.$refs.deedOfManagement.$el.querySelector("input").value = "";
              try {
                this.doc.deedOfManagement.name = name;
                this.doc.deedOfManagement.file = await this.convertFileToBase64(
                  file
                );
                this.doc.deedOfManagement.ex = extension;
              } catch (error) {
                console.error("Error converting file to Base64:", error);
              }
              break;
          }
        }
      }
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = function () {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };

        reader.onerror = function (error) {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },
    resetFile(name) {
      switch (name) {
        case "ktp":
          this.doc.cifIdentity.name = null;
          this.doc.cifIdentity.file = null;
          this.doc.cifIdentity.ex = null;
          break;
        case "loanForm":
          this.doc.loanForm.name = null;
          this.doc.loanForm.file = null;
          this.doc.loanForm.ex = null;
          break;
        case "deedOfManagement":
          this.doc.deedOfManagement.name = null;
          this.doc.deedOfManagement.file = null;
          this.doc.deedOfManagement.ex = null;
          break;
      }
    },
    listenToPurpose() {
      if (this.form.purpose === "05" || this.form.purpose === "07") {
        this.switcher.uploadedForm = false;
        // reset loan form
        this.doc.loanForm.name = "";
        this.doc.loanForm.file = "";
        this.doc.loanForm.ex = "";
        // reset deedOfManagement form
        this.doc.deedOfManagement.name = "";
        this.doc.deedOfManagement.file = "";
        this.doc.deedOfManagement.ex = "";
        // reset cifType Badan && change cifType to Individu
        if (this.switcher.cifType === "2") {
          this.resetCifForm();
        }
        this.switcher.cifType = "1";
        this.switcher.cifTypeOn = false;
      } else {
        this.switcher.uploadedForm = true;
        this.switcher.cifTypeOn = true;
      }
    },
    listenToCifType() {
      // reset deedOfManagement form
      this.doc.deedOfManagement.name = "";
      this.doc.deedOfManagement.file = "";
      this.doc.deedOfManagement.ex = "";

      this.resetCifForm();
    },
    resetCifForm() {
      this.form.cifName = "";
      this.form.cifCode = "";
      this.form.cifGender = "";
      this.form.cifBirthPlace = "";
      this.form.cifBirthDate = "";
      this.form.cifMother = "";
      this.form.cifCodePic = "";
      this.doc.cifIdentity.name = "";
      this.doc.cifIdentity.file = "";
      this.doc.cifIdentity.ex = "";
    },
    async checkAO() {
      this.animation.isLoading = true;
      try {
        await this.$axios.get("debitur/ao/" + this.form.aoCode).then((resp) => {
          console.log(resp.data);
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.switcher.isAo = true;
            this.form.name = data.accountOfficerName
              ? data.accountOfficerName
              : "-";
            this.form.branch = data.officeName ? data.officeName : "-";
            this.form.aoId = data.accountOfficerId
              ? data.accountOfficerId
              : "-";
          } else if (resp.data.code === "DATA_NOT_FOUND") {
            this.$store.dispatch("showError", {
              text: resp.data.message ? resp.data.message : "",
            });
          } else {
            this.$store.dispatch("showError", {
              text: resp.data.message ? resp.data.message : "",
            });
          }
        });
      } catch (error) {
        this.$store.dispatch("showError", {
          text: resp.data.message ? resp.data.message : "",
        });
      } finally {
        this.animation.isLoading = false;
      }
    },
    routeToBack() {
      window.location.reload();
    },
    async addDebitur() {
      const data = {
        accountOfficerCode: this.form.aoCode,
        debiturEmail: this.form.email,
        fromSection: this.form.position,
        referenceDebiturId: this.form.aoId,
        categoryDebiturId: this.switcher.cifType,
        cifName: this.form.cifName,
        cifIdentityNumber: this.form.cifCode,
        cifGender: this.form.cifGender ? this.form.cifGender : "",
        cifBirthPlace: this.form.cifBirthPlace ? this.form.cifBirthPlace : "",
        cifBirthDate: this.form.cifBirthDate,
        cifMotherName: this.form.cifMother ? this.form.cifMother : "",
        uploadedFiles: [
          {
            fileType: "identity",
            fileName: this.doc.cifIdentity.name
              ? this.doc.cifIdentity.name
              : "",
            data: this.doc.cifIdentity.file,
          },
          {
            fileType: "loan",
            fileName: this.doc.loanForm.name ? this.doc.loanForm.name : "",
            data: this.doc.loanForm.file,
          },
          {
            fileType: "akta",
            fileName: this.doc.deedOfManagement.name
              ? this.doc.deedOfManagement.name
              : "",
            data: this.doc.deedOfManagement.file,
          },
        ],
      };

      this.animation.isLoading = true;
      try {
        await this.$axios.post("debitur", data).then(() => {
          this.$store.dispatch("showSuccess", {
            text: "Data berhasil ditambahkan!",
            router: this.$router,
          });
        });
      } catch (error) {
        this.$store.dispatch("showError", {
          text: error.response.data.message ? error.response.data.message : "",
        });
        console.log(error);
      } finally {
        this.animation.isLoading = false;
      }
    },
  },
};
</script>
