import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "tailwindcss/dist/tailwind.min.css";
import "./assets/scss/style.scss";
import './vee-validate';

const app = createApp(App).use(store).use(router).use(Buefy);

// const axiosinstance = axios.create({
//   baseURL: process.env.VUE_APP_BASE_URL_GATEWAY,
//   timeout: 180000, // 3 menit
//   headers: {
//     //   Authorization: "Bearer",
//     "Content-Type": "application/json",
//   },
// });

const axiosinstance = axios.create({
  baseURL: 'https://gw.dev.bprshikp.co.id/incoming-service/',
  timeout: 180000,
  headers: {
      'Content-Type': 'application/json',
  }
})

app.config.globalProperties.$axios = { ...axiosinstance };
app.mount("#app");
