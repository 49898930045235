import { createRouter, createWebHistory } from 'vue-router'
import FormSlikOjk from '@/views/FormSlikOjk.vue'
import Page404 from '@/views/Page404.vue'

const routes = [
  {
    path: '/',
    redirect: '/formulir-permohonan',
  },
  {
    path: '/formulir-permohonan',
    name: 'Form Slik OJK',
    component: FormSlikOjk
  },
  {
    path: "/:catchAll(.*)",
    component: Page404,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router
