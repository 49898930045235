import { createStore } from 'vuex'
import Swal from 'sweetalert2';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    showSuccess({}, {text, path, router}){
      Swal.fire({
        title: "Berhasil!",
        text: text,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        if(path){
          router.push(path);
        }else{
          window.location.reload();
        }
      })
    },
    showError({}, {text}){
      Swal.fire({
        title: "Terjadi Kesalahan!",
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
        text: text,
    });
    },
  },
  modules: {
  }
})
